body {
margin: 0;
padding: 0;
box-sizing: border-box;
outline: none;
-webkit-touch-callout: none; 
-webkit-user-select: none;
 -khtml-user-select: none; 
   -moz-user-select: none; 
    -ms-user-select: none; 
        user-select: none; 
}