

.react-datepicker__input-container div {
    display: block !important;
    width: 80px;
}

.react-datepicker-wrapper input {
    background-color: #FFF;
    width: 80px  !important;
    height: 40px;
    text-align: center;
    border: none;
    border-radius: 6px;
}